<template>
  <div style="width: 100%; height: 100%" class="fadeIn">
    <div class="header">
      <div style="font-size: 18px">
        {{ $route.query.projectName }} 计数明细 ：<i
          @click="dateVisible = true"
          class="el-icon-date"
          style="font-size: 20px; color: blue"
        ></i>
      </div>
      <div
        style="
          width: 60px;
          cursor: pointer;
          color: rgb(2, 185, 128);
          font-size: 14px;
          line-height: 28px;
        "
        @click="$router.go(-1)"
      >
        <i class="el-icon-arrow-left"></i>返回
      </div>
    </div>
    <div class="oeebody">
      <!-- 折线 -->
      <div style="position: relative">
        <div id="number" style="width: 100%; height: 240px"></div>
      </div>
      <el-table
        :data="tableDataAll"
        :row-class-name="tableRowClassName"
        :span-method="objectSpanMethod"
        height="350"
        border
        :header-cell-style="{ background: '#4272c8', color: '#fff' }"
      >
        <el-table-column
          width="120"
          prop="date"
          label="日期"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shiftName"
          label="班次"
          align="center"
          v-if="radio == 2"
        ></el-table-column>
        <el-table-column
          prop="totalNumber"
          label="总个数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lampGreenDuration"
          width="130"
          label="绿灯时长"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.lampGreenDuration | timeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="averageDuration" label="平均时长" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.averageDuration + "秒" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="singleDuration" label="理论时长" align="center">
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                color: #5470c6;
                cursor: pointer;
                margin-right: 5px;
              "
              @click="
                (dialogVisible = true),
                  (type = 2),
                  (rowData = row),
                  (remark = row.remark),
                  (time = row.singleDuration)
              "
              >{{ row.singleDuration }}秒</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="efficiency"
          label="效率"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="详情" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              style="text-decoration: underline"
              @click="getDetail(row)"
              >明细</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" width="200">
          <template slot-scope="{ row }">
            <el-input
              readonly
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 2 }"
              placeholder="请输入"
              v-model="row.remark"
              @focus="changeText(row)"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="计数详情"
      :visible.sync="dialogdetail"
      width="90%"
      top="3%"
      @close="countFrom.current = 1"
    >
      <div id="bar" style="width: 100%; height: 150px; position: relative">
        <van-icon
          name="question-o"
          style="position: absolute; left: 20%; top: 2%; z-index: 99"
          @click="show = true"
        />
        <van-image-preview v-model="show" :images="srcList">
        </van-image-preview>
      </div>
      <!-- tabel表格 -->
      <el-table
        @row-click="changRow"
        :header-cell-style="{ background: '#a3b4c1', color: '#fff' }"
        :data="tableData"
        stripe
        height="320"
        style="width: 100%"
      >
        <el-table-column
          prop="modifyTime"
          label="上传时间"
          :formatter="formatter"
          width="75"
        >
        </el-table-column>
        <el-table-column prop="js" label="个数" width="55"> </el-table-column>
        <el-table-column prop="zsj" label="循环时长" width="72">
        </el-table-column>
        <el-table-column prop="fsj" label="等待时长" width="72">
        </el-table-column>
        <el-table-column prop="hsj" label="触发时长" width="72">
        </el-table-column>
      </el-table>
      <el-row>
        <el-pagination
          :pager-count="3"
          popper-class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="countFrom.current"
          :page-size="countFrom.size"
          layout="total, prev, pager, next"
          :total="countFrom.total"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
    <el-dialog
      title="查询方式"
      width="90%
    "
      style="text-align: left"
      :visible.sync="dateVisible"
    >
      <van-notice-bar
        v-if="dateVisible"
        text="周查询和月查询为本年所有周和所有月,无需上传日期！"
      />
      选择方式：
      <van-radio-group v-model="radio" direction="horizontal">
        <van-radio :name="1" icon-size="14px">日查询</van-radio>
        <van-radio :name="2" icon-size="14px">班查询</van-radio>
        <van-radio :name="3" icon-size="14px">周查询</van-radio>
        <van-radio :name="4" icon-size="14px">月查询</van-radio>
      </van-radio-group>
      <el-row v-if="radio == 1 || radio == 2">
        <div style="margin: 10px 0">
          开始日期：
          <el-date-picker
            v-model="dateValue.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div style="margin: 10px 0">
          结束日期：
          <el-date-picker
            v-model="dateValue.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onetrendChange(), (dateVisible = false)"
          >确 定</el-button
        >
        <el-button @click="dateVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="type == 1 ? '修改备注' : '设置理论时长'"
      :visible.sync="dialogVisible"
      width="70%"
      @close="remark = ''"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 2 }"
        v-model="remark"
        placeholder="请输入备注"
        v-if="type == 1"
      >
      </el-input>
      <el-input
        v-else
        v-model="time"
        size="medium"
        style="width: 60%"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="(dialogVisible = false), getAnalysis()"
          size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
let that;
export default {
  data() {
    return {
      pickerMinDate: "",
      rowData: {},
      rowspan: [],
      shiftData: [],
      dialogdetail: false,
      tableDataAll: [],
      radio: 1,
      type: 1,
      remark: "",
      show: false,
      dateVisible: false,
      url: require("../assets/img/wenhao.png"),
      srcList: [require("../assets/imgproject/jssm.png")],
      loading: true,
      FrequencyList: [],
      efficiencyData: {},
      datetime: "",
      lineData: [],
      chartLine: "",
      AllTime: [],
      chart: "",
      time: 0,
      dialogVisible: false,
      zoom: 1,
      countFrom: {
        size: 10,
        current: 1,
        counterCountId: "",
        total: 0,
        projectId: "",
        endTime: "",
        startTime: "",
        showType: 1,
      },
      tableData: [],
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      dateValue: {
        startDate: "",
        endDate: "",
      },

      setBar(Xdata, data) {
        let option = {
          // backgroundColor: '#2c343c',
          title: {
            text: "计数时长明细",
            textStyle: {
              fontSize: "14",
            },
          },
          tooltip: {
            confine: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {},
          grid: {
            top: "20%",
            left: "3%",
            right: "5%",
            bottom: "1%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: Xdata,
              axisLabel: {
                //文字倾斜
                interval: 0,
                rotate: 40,
              },
            },
          ],
          dataZoom: [
            {
              type: "inside",
              // start: 99,
              // end: 100,
            },
            // {
            //   type: 'slider',
            //           start: 99,
            //           end: 100,
            // }
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: data,
        };
        let myChart = echarts.getInstanceByDom(document.getElementById("bar"));
        if (myChart == null) {
          myChart = echarts.init(document.getElementById("bar"));
        }
        myChart.clear();
        myChart.setOption(option, true);
        this.chart = myChart;
      },
    };
  },
  filters: {
    timeFiter(val) {
      if (val) {
        // return val
        return val.substring(5);
      }
    },
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (this.radio == 2) {
          const _row = this.rowspan[rowIndex];
          const _col = _row > 0 ? 1 : 0; // 如果这一行隐藏了，这列也隐藏
          return {
            rowspan: _row,
            colspan: _col,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
    },
    getAnalysis() {
      if (this.radio == 2) {
        let from = {
          projectId: this.rowData.projectId,
          endTime: this.rowData.endTime,
          startTime: this.rowData.startTime,
          averageDuration: this.rowData.averageDuration,
          counterCountShiftId: this.rowData.counterCountShiftId,
          singleDuration: this.time,
          remark: this.remark,
        };
        this.$api.triColorLamp.upCounterShift(from).then((res) => {
          if (res.code == 200) {
            this.getbar();
          }
        });
      } else {
        let from = {
          projectId: this.rowData.projectId,
          endTime: this.rowData.endTime,
          startTime: this.rowData.startTime,
          averageDuration: this.rowData.averageDuration,
          counterCountId: this.rowData.counterCountId,
          singleDuration: this.time,
          remark: this.remark,
        };
        this.$api.triColorLamp.upCounterDay(from).then((res) => {
          if (res.code == 200) {
            this.getbar();
          }
        });
      }
    },
    clickBar({ name }) {
      if (this.radio == 1 || this.radio == 2) {
        return;
      }
      this.tableDataAll = [];
      let data = that.shiftData.filter((it) => it.date == name);
      //班次点击柱状图
      if (this.radio == 2) {
        //点击图表，过滤数据显示列表
        data[0].counterCountList.forEach((item) => {
          if (item.counterCount) {
            item.counterCount.date = data[0].date;
            item.counterCount.shiftName = item.shiftName;
            this.tableDataAll.push(item.counterCount);
          }
        });
      }
      //周月点击柱状图
      else if (this.radio == 3 || this.radio == 4) {
        if (data[0].counterCount) {
          let from = {
            projectId: this.$route.query.projectId,
            startDate: data[0].counterCount.startTime.split(" ")[0],
            endDate: data[0].counterCount.endTime.split(" ")[0],
            type: 1,
          };
          this.$api.triColorLamp.counterCount(from).then((res) => {
            if (res.code == 200) {
              res.data.forEach((item) => {
                if (item.counterCount) {
                  item.counterCount.date = item.date;
                  this.tableDataAll.push(item.counterCount);
                }
              });
            }
          });
        }
      }
    },
    async getDetail(row) {
      if (this.radio == 2) {
        this.countFrom.showType = 2;
      } else {
        this.countFrom.showType = 1;
      }
      this.dialogdetail = true;
      (this.countFrom.projectId = row.projectId),
        (this.countFrom.endTime = row.endTime),
        (this.countFrom.startTime = row.startTime),
        (this.countFrom.counterCountId =
          row.counterCountId || row.counterCountShiftId);
      that.getCountList();
    },
    async onetrendChange() {
      if (this.radio == 1 || this.radio == 2) {
        await that.getbar();
        //日查询 不做处理
      } else {
        await that.getbar();
        let data = this.shiftData[0].counterCount;
        if (data) {
          let from = {
            projectId: this.$route.query.projectId,
            startDate: data.startTime.split(" ")[0],
            endDate: data.endTime.split(" ")[0],
            type: 1,
          };
          this.$api.triColorLamp.counterCount(from).then((res) => {
            if (res.code == 200) {
              res.data.forEach((item) => {
                if (item.counterCount) {
                  item.counterCount.date = item.date;
                  this.tableDataAll.push(item.counterCount);
                }
              });
            }
          });
        }
        //周月查询
        //再次请求 获取列表数据
      }
    },
    //修改备注
    changeText(data) {
      this.rowData = data;
      this.dialogVisible = true;
      this.type = 1;
      this.remark = data.remark;
      this.time = data.singleDuration;
    },
    formatter(val) {
      return val.modifyTime.split(" ")[1];
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.radio == 2) {
        if (row.id % 2 == 1) {
          return "freOne";
        } else if (row.id % 2 == 0) {
          return "freTow";
        }
      } else {
        if (rowIndex % 2 == 0) {
          return "freOne";
        } else if (rowIndex % 2 == 1) {
          return "freTow";
        }
      }

      return "";
    },
    //行点击事件
    changRow(row, column, event) {
      let index = this.chart.getOption().xAxis[0].data.length;
      this.chart.dispatchAction({
        type: "downplay",
        seriesIndex: index,
        dataIndex: index,
      });
      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 9 - row.id,
      });
      this.chart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: 9 - row.id,
      });
    },
    handleSizeChange(val) {
      this.countFrom.size = val;
      this.getCountList();
    },
    handleCurrentChange(val) {
      this.countFrom.current = val;
      this.getCountList();
    },
    //获取分页数据
    async getCountList() {
      this.loading = true;
      await this.$api.triColorLamp.counterDetail(this.countFrom).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.countFrom.total = res.data.total;
          this.tableData.forEach((item, index) => {
            item.id = index;
          });
        }
        let arr = [...res.data.records];
        let number = []; //x轴
        let zsj = []; //总时间
        let hsj = []; //合时间
        let fsj = []; //分时间
        let data = []; //生产时间
        let data1 = []; //浪费时间
        if (res.data.records) {
          arr.reverse().forEach((item) => {
            number.push(item.js + `(${item.zsj}秒)`);
            // hsj.push(item.hsj);
            // fsj.push(item.fsj);
            zsj.push(item.zsj);
            data.push(this.time);
            if (item.zsj > this.time && this.time * 1) {
              data1.push((item.zsj * 10 - this.time * 10) / 10);
            } else {
              data1.push(0);
            }
          });
        }
        let Echarts = [];
        if (this.time * 1) {
          //有时间
          Echarts = [
            {
              barMaxWidth: 40,
              name: "生产时长",
              barGap: 0,
              type: "bar",
              stack: "AE",
              itemStyle: {
                color: "#9edf7f",
              },
              data: data,
              // emphasis: {
              //   focus: 'series'
              // },
            },
            {
              barMaxWidth: 40,
              name: "浪费时长",
              barGap: 0,
              type: "bar",
              stack: "AE",
              itemStyle: {
                color: "#ffdc60",
              },
              data: data1,
              // emphasis: {
              //   focus: 'series'
              // },
            },
          ];
        } else {
          //没时间
          Echarts = [
            {
              barMaxWidth: 40,
              name: "循环时长",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "rgba(0, 0, 0, 0.4)",
              },
              data: zsj,
            },
          ];
        }

        this.setBar(number, Echarts);
        return;
      });
    },
    async getbar() {
      let from = {
        projectId: this.$route.query.projectId,
        startDate: this.dateValue.startDate,
        endDate: this.dateValue.endDate,
        type: this.radio,
      };
      await this.$api.triColorLamp.counterCount(from).then((res) => {
        if (res.code == 200) {
          this.shiftData = res.data;
          this.tableDataAll = [];
          if (this.radio != 2) {
            //非班次处理
            let bardata = [];
            let xData = [];
            res.data.forEach((item) => {
              xData.unshift(item.date);
              bardata.unshift(
                item.counterCount ? item.counterCount.totalNumber : 0
              );
              //日查询列表
              if (item.counterCount && this.radio == 1) {
                item.counterCount.date = item.date;
                this.tableDataAll.push(item.counterCount);
              }
            });
            let series = [
              {
                barMaxWidth: 40,
                data: bardata,
                type: "bar",
              },
            ];
            that.setNumber(xData, series);
          } else {
            //保存班次信息点击时使用
            this.setShiftBar(res.data);
            let list = res.data;
            let id = 0;
            list.forEach((it) => {
              let flag = false;
              id++;
              let num = 0;
              it.counterCountList.forEach((item) => {
                if (item.counterCount) {
                  flag = true;
                  num++;
                  item.counterCount.id = id;
                  item.counterCount.date = it.date;
                  item.counterCount.shiftName = item.shiftName;
                  this.tableDataAll.push(item.counterCount);
                }
              });
              console.log(this.tableDataAll);
              if (flag) {
                this.rowspan.push(num);
                if (num > 1) {
                  for (let i = 0; i < num - 1; i++) {
                    this.rowspan.push(0);
                  }
                }
              }
            });
            // this.setShiftBar(res.data);
            // let list = res.data;
            // list.forEach((it) => {
            //   it.counterCountList.forEach((item) => {
            //     if (item.counterCount) {
            //       item.counterCount.date = it.date;
            //       item.counterCount.shiftName = item.shiftName;
            //       this.tableDataAll.push(item.counterCount);
            //     }
            //   });
            // });
            //设置列表
            //设置图表
          }
        }
        return;
      });
    },
    setShiftBar(data) {
      let xData = [];
      let name1 = "";
      let name2 = "";
      let name3 = "";
      let data1 = [];
      let data2 = [];
      let data3 = [];
      data.forEach((item) => {
        name1 = item.counterCountList[0]?.shiftName;
        name2 = item.counterCountList[1]?.shiftName;
        name3 = item.counterCountList[2]?.shiftName;
        let number1 = item.counterCountList[0]?.counterCount
          ? item.counterCountList[0].counterCount.totalNumber
          : 0; // 数量
        let number2 = item.counterCountList[1]?.counterCount
          ? item.counterCountList[1].counterCount.totalNumber
          : 0; // 数量
        let number3 = item.counterCountList[2]?.counterCount
          ? item.counterCountList[2].counterCount.totalNumber
          : 0; // 数量
        xData.unshift(item.date);
        data1.unshift(number1);
        data2.unshift(number2);
        data3.unshift(number3);
      });
      let series = [
        {
          type: "bar",
          data: data1,
          name: name1,
          barMaxWidth: 40,
        },
      ];
      if (name2) {
        series.push({
          type: "bar",
          data: data2,
          name: name2,
          barMaxWidth: 40,
        });
      }
      if (name3) {
        series.push({
          type: "bar",
          data: data3,
          name: name3,
          barMaxWidth: 40,
        });
      }
      that.setNumber(xData, series);
    },
    setNumber(xdata, data) {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "20",
          left: "70",
          right: "1%",
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            //文字倾斜
            interval: 0,
            rotate: 40,
          },
        },
        dataZoom: [
          {
            type: "inside",
            // start: 99,
            // end: 100,
          },
          // {
          //   type: 'slider',
          //           start: 99,
          //           end: 100,
          // }
        ],
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} 个",
          },
        },
        series: data,
      };
      let myChart = echarts.getInstanceByDom(document.getElementById("number"));
      if (myChart == null) {
        myChart = echarts.init(document.getElementById("number"));
      }
      myChart.clear();
      myChart.setOption(option, true);
      myChart.off("click");
      myChart.on("click", function (params) {
        that.clickBar(params);
      });
    },
  },
  mounted() {
    // this.zoom = 1 / document.body.style.zoom;
    //  window.addEventListener("resize", () => {
    //   this.zoom = 1 / document.body.style.zoom;
    //   })
    that = this;
    this.countFrom.datetime = this.dayjs().format("YYYY-MM-DD");
    let day = this.dayjs().format("YYYY-MM-DD");
    let beforeDay = this.dayjs(this.dayjs().subtract(7, "day").$d).format(
      "YYYY-MM-DD"
    ); //7天前
    that.dateValue.startDate = beforeDay;
    that.dateValue.endDate = day;
    that.getbar();
  },
};
</script>

<style>
.el-table .freOne {
  background: #cfd6e9;
}

.el-table .freTow {
  background: #e7eaf5;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}
.header {
  height: 50px;
  padding: 0px 20px;
  border-radius: 15px 15px 0px 0px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oeebody {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: auto;
  padding-bottom: 50px !important;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
